import React from "react";
import { Link } from "gatsby";

import StaticLayout from "../components/StaticLayout";
import imgSrc from "../images/Spork.jpg";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-9"
        className="post-9 post type-post status-publish format-standard hentry category-geen-categorie"
      >
        <header className="entry-header">
          <h1 className="entry-title">Privélessen</h1>
          <div className="entry-meta" />
          {/* .entry-meta */}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            Bij Dance Square kan iedereen terecht voor privélessen, of je nu een
            recreatieve danser bent of een wedstrijddanser. Heb je geen tijd om
            elke week deel te nemen aan groepslessen, of wil je graag een
            intensievere begeleiding, eventueel bovenop de groepslessen? Dan
            zijn privélessen de oplossing voor jou. Je leert wat je wilt,
            wanneer je wilt! Privélessen nemen betekent niet dat je alleen moet
            dansen, er is ook de mogelijkheid om met een aantal vrienden
            privélessen te nemen. Je kan zelfs privédanslessen cadeau doen met
            onze cadeaukaart.
          </p>
          <p>
            <u>Recreatieve danser</u>
          </p>
          <p>
            In onze privélessen krijg je de onverdeelde aandacht van de
            dansleraar. Dat heeft natuurlijk als voordeel dat de danslessen
            volledig op jouw maat gemaakt kunnen worden, en dat we aandacht
            kunnen geven aan jouw werkpunten. Dat kunnen passen zijn die je nog
            niet onder de knie hebt, maar ook je houding, je techniek en
            choreografieën uit de cursussen. Later instromen in groepslessen is
            enkel mogelijk als je al enige danservaring hebt, dus ook daarvoor
            zijn onze privélessen het ideale opstapje!
          </p>
          <h2>
            <img
              className="size-medium wp-image-176 aligncenter"
              src={imgSrc}
              alt=""
              width={200}
              height={300}
            />
          </h2>
          <p>
            <u>Wedstrijddanser</u>
          </p>
          <p>
            Dans jij met je partner vlot door regionale, nationale of
            internationale wedstrijden? Of heb je die ambitie? Wij bieden
            dansparen ondersteuning op gebied van choreografie, techniek en
            houding. Bij wedstrijddansen is het dansen natuurlijk van groot
            belang, maar ook over de dingen die er allemaal bij komen kijken,
            kan je bij ons terecht voor advies. Samen werken we een persoonlijk
            trainingsschema uit met bijzondere aandacht voor kleding en
            voedingsadvies.
          </p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1920 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1925,0" />
        </svg>
      </div>
      <nav className="navigation post-navigation" role="navigation">
        <h2 className="screen-reader-text">Berichtnavigatie</h2>
        <div className="nav-links">
          <div className="nav-previous">
            <Link to="/">Terug naar de startpagina</Link>
          </div>
        </div>
      </nav>
    </main>
  </StaticLayout>
);

export default Page;
